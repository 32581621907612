import React from "react"
import styled from "styled-components"
import colors from "../utils/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/pro-light-svg-icons"

const Button = styled.a`
  /* This renders the buttons above... Edit me! */
  display: inline-block;
  padding: 0.3rem 0;
  margin: 0.5rem 0.7rem;
  width: 11rem;
  background: ${colors.black};
  color: white;
  font-family: "Open Sans Condensed";
  font-weight: 400;
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
`

export default () => (
  <Button href="tel:+4992099186393">
    <FontAwesomeIcon style={{ marginRight: 10 }} icon={faPhone} /> Festnetz
  </Button>
)
