import { StaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"

const Openinghours = styled.div``

const FooterOpeninghours = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(slug: { eq: "kontakt" }) {
          slug
          id
          acf {
            anschrift
            telefonnummer
            e_mail
            offnungszeiten
          }
        }
      }
    `}
    render={data => (
      <Openinghours>
        <div
          dangerouslySetInnerHTML={{
            __html: data.wordpressPage.acf.offnungszeiten,
          }}
        />
      </Openinghours>
    )}
  />
)

export default FooterOpeninghours
