/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { mq, colors } from "../utils/presets"
import styled from "styled-components"
import "../fonts/custom-fonts.css"
import "./layout.scss"
import Header from "./header"
import ContactButton from "./contactButton"
import AnrufenButton from "./anrufenButton"
import WhatsAppButton from "./WhatsAppButton"
import Footer from "./footer"

const FixedBottomMenu = styled.div`
  position: fixed;
  bottom: 0;
  background: ${colors.grey};
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-bottom: calc(0.3rem + env(safe-area-inset-bottom));
  padding-top: 0.3rem;
  ${mq.xl} {
    display: none;
  }
`

const Main = styled.main``

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.wordpressSiteMetadata.name} />
        <div>
          <Main>{children}</Main>
          {/* <Footer /> */}
          <FixedBottomMenu>
            <ContactButton />
            <AnrufenButton />
            <WhatsAppButton />
          </FixedBottomMenu>
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
