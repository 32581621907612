import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { colors } from "../utils/presets"

const MenuList = styled.ul`
  list-style: none;
  background: white;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  li {
    margin: 0;
    a {
      border-bottom: 3px solid ${colors.black};
      padding-bottom: 0.3rem;
      padding-top: 0.7rem;
      display: inline-block;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      color: ${colors.black};
      text-decoration: none;
      font-family: "Millard";
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.5rem;
    }
  }
`

const MenuItems = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(name: { eq: "Hauptmenu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <MenuList id="mobileMenu">
        <li key="home">
          <Link
            to="/"
            activeStyle={{
              borderBottom: "3px solid #4a4a4a",
            }}
          >
            Home
          </Link>
        </li>
        {data.wordpressWpApiMenusMenusItems.items.map(item => (
          <li key={item.object_slug}>
            <Link
              to={`/${item.object_slug}`}
              activeStyle={{
                borderBottom: "3px solid #4a4a4a",
              }}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </MenuList>
    )}
  />
)

export default MenuItems
