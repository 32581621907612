import React from "react"
import { Link } from "gatsby"
import logo from "../images/mrbarklogo.svg"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import FooterOpeningHours from "./footerOpeninghours"

const Year = new Date().getFullYear()

const Footer = styled.div`
  width: 100%;
  background: ${colors.black};
`

const FooterInner = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  color: white;
  font-family: "Open Sans Condensed";
  padding: 4rem 1rem 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  h2 {
    font-family: "Open Sans Condensed";
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.6rem;
    color: white;
    text-transform: uppercase;
  }
  a {
    color: white;
    text-decoration: none;
  }
  .flex {
    width: 50%;
    flex: 1 0 auto;
    padding-bottom: 2rem;
    ${mq.tablet} {
      width: 25%;
    }
    a {
      text-decoration: underline;
      svg {
        margin-left: 0.4rem;
      }
    }
  }
  .marken {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-flow: row wrap;
    line-height: 2;
    align-content: flex-start;
    width: 25%;
    ${mq.tablet} {
      width: 25%;
      line-height: initial;
    }
    h2 {
      width: 100%;
    }
    a {
      width: 50%;
      flex: 0 1 auto;
      line-height: 48px;
    }
    ${mq.xl} {
      width: 25%;
      h2 {
        height: 45px;
      }
      a {
        line-height: 1;
        height: 30px;
      }
    }
  }
  .logobg {
    background: red;
  }
`

const Socialmedia = styled.div`
  width: 100%;
  margin: 0 0 2.5rem;
  flex: 1 0 auto;
  ${mq.tablet} {
    width: 25%;
  }
  ${mq.xl} {
    width: 20%;
  }
  svg {
    margin-right: 1.5rem;
  }
`

const Bottomwrap = styled.div`
  width: 100%;
  text-align: center;
  background: #222;
  padding-top: 3rem;
  padding-bottom: 2rem;
  color: #ccc;
  font-size: 0.9rem;
  img {
    margin-bottom: 2rem;
  }
  a {
    color: #ddd;
    text-decoration: underline;
    line-height: 25px;
  }
`

export default () => (
  <div>
    <Footer>
      <FooterInner>
        <address className="flex anschrift">
          <h2>Anschrift</h2>
          Mr. Bark
          <br />
          Schlehenbergstr. 11
          <br />
          95448 Bayreuth
          <br />
          Tel: <a href="tel:+4992099186393">09209 9186393</a>
          <br />
          Mobil: <a href="tel:+4917697722551">0176 97722551</a>
          <br />
          E-Mail: <a href="mailto:hello@mr-bark.de">hello@mr-bark.de</a>
          <br />
        </address>
        <div className="flex anschrift">
          <h2>Öffnungs&shy;zeiten</h2>
          <FooterOpeningHours />
        </div>
        <div className="flex marken">
          <h2>Unsere Marken</h2>
          <a
            href="https://www.prunkhund.de/"
            target="_blank"
            rel="noopener noreferrer"
            alt="Prunkhund"
            title="Link zu Prunkhund"
          >
            Prunkhund
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
          <a
            href="https://www.goldhund.de/"
            target="_blank"
            rel="noopener noreferrer"
            alt="Goldhund"
            title="Link zu Goldhund"
          >
            Goldhund
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
          <a
            href="https://rudelliebe.de/"
            target="_blank"
            rel="noopener noreferrer"
            alt="Rudelliebe"
            title="Link zu Rudelliebe"
          >
            Rudelliebe
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
          <a
            href="https://www.treusinn.com/"
            target="_blank"
            rel="noopener noreferrer"
            alt="Treusinn"
            title="Link zu Treusinn"
          >
            Treusinn
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
          <a
            href="https://www.isartau.de/"
            target="_blank"
            rel="noopener noreferrer"
            alt="Isartau"
            title="Link zu Isartau"
          >
            Isartau
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
          <a
            href="https://www.platinum.com"
            target="_blank"
            rel="noopener noreferrer"
            alt="Platinum"
            title="Link zu Platinum"
          >
            Platinum
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
          <a
            href="https://naturaldogcompany.com/"
            target="_blank"
            rel="noopener noreferrer"
            alt="Natural Dog Company"
            title="Link zu Natural Dog Company"
          >
            Natural Dog Company
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
          <Link to="/" alt="Mister Bark" title="Link zu Mister Bark">
            Mister Bark
          </Link>
        </div>

        <Socialmedia>
          <h2>Social Media</h2>
          <a
            href="https://www.facebook.com/Mister-BARK-Carlo-Friends-1087678031434818"
            target="_blank"
            rel="noopener noreferrer"
            title="Mr. Bark auf Facebook"
            alt="Mr. Bark auf Instagram"
          >
            <FontAwesomeIcon icon={faFacebook} size="3x" />
          </a>
          <a
            href="https://www.instagram.com/mr_bark_carloandfriends/"
            target="_blank"
            rel="noopener noreferrer"
            title="Mr. Bark auf Instagram"
            alt="Mr. Bark auf Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} size="3x" />
          </a>
        </Socialmedia>
      </FooterInner>
      <Bottomwrap>
        <Link to="/" alt="Home">
          <img src={logo} alt="Mr. Bark" style={{ width: "320px" }} />
        </Link>
        <br />
        <a href="/Mr-Bark-Logo.zip" alt="Logo Download">
          Logo Download
        </a>{" "}
        -{" "}
        <Link to="/impressum" alt="Impressum">
          Impressum
        </Link>{" "}
        -{" "}
        <Link to="/datenschutz" alt="Datenschutz">
          Datenschutz
        </Link>
        <br />© {Year} Mr. Bark
      </Bottomwrap>
    </Footer>
  </div>
)
