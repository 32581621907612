import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { mq, colors } from "../utils/presets"
import styled from "styled-components"
import Navbar from "./Navbar"
import Headroom from "react-headroom"

const HeaderWrapper = styled.div`
  background: ${colors.black};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  /* position: fixed; */
  z-index: 9;
  width: 100%;
  ${mq.tablet} {
    box-shadow: none;
    background: transparent;
    max-width: 1600px;
    margin: 0 auto;
  }
`

const HeaderInside = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
        wordpressWpApiMenusMenusItems(name: { eq: "Hauptmenu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <Headroom>
        <HeaderWrapper>
          <HeaderInside>
            <Navbar />
          </HeaderInside>
        </HeaderWrapper>
      </Headroom>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
