import React from "react"
import { Link } from "gatsby"
import logowhite from "../images/mrbarklogowhite.svg"
import logoblack from "../images/mrbarklogoblack.svg"
import MenuItems from "./menuitems"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      isOpen: false,
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
        isOpen: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "sidebar-is-open",
              isOpen: "true",
            })
          : this.setState({
              navBarActiveClass: "",
              isOpen: "false",
            })
      }
    )
  }

  render() {
    return (
      <nav id="mainnav" role="navigation" aria-label="main-navigation">
        <div className="wrapper">
          <Link
            to="/"
            className="navbar-item"
            style={{ paddingTop: 20, paddingLeft: 15, paddingBottom: 20 }}
          >
            <img
              className="logowhite"
              src={logowhite}
              alt="Mr. Bark"
              style={{ width: "200px" }}
            />
            <img
              className="logoblack"
              src={logoblack}
              alt="Mr. Bark"
              style={{ width: "200px" }}
            />
          </Link>

          {/* Hamburger menu */}
          <button
            onClick={() => this.toggleHamburger()}
            id="menu-toggle"
            className={`menu-toggle mobile-menu-button ${
              this.state.navBarActiveClass
            }`}
            aria-label="Menu"
            aria-expanded={this.state.isOpen}
            aria-controls="mobileMenu"
          >
            <div className={`container ${this.state.navBarActiveClass}`}>
              {/* Show Menu */}
              <div className="bar1" />
              <div className="bar2" />
              <div className="bar3" />
            </div>
          </button>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${this.state.navBarActiveClass}`}
        >
          <MenuItems />
        </div>
      </nav>
    )
  }
}

export default Navbar
